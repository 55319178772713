import styled, { css, keyframes } from 'styled-components';
import { saasModernTheme } from 'common/theme/saasModern';

// Create a styled component for the gradient border
export const GradientBorder = styled.div`
  transition: border 500ms;
  height: Hug (94px) px;
  padding: 12px 16px 12px 16px;
  border-radius: 12px 12px 12px 12px;
  opacity: 0px;
  background-color: ${({ active }) =>
    active ? saasModernTheme.colors.redSecondary : 'rgba(255, 255, 255, 0.05)'};
  cursor: pointer;
  flex: 1; /* or use flex-basis: 100px; flex-grow: 1; for specific width */
`;

export const SvgContainer = styled.article`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 500ms;
  svg:nth-child(1) {
    display: ${({ isHovered }) => (isHovered ? 'none' : 'block')};
  }
  svg:nth-child(2) {
    display: ${({ isHovered }) => (isHovered ? 'block' : 'none')};
  }
`;

export const Typography = styled.h2`
  
  font-size: 14px;
  font-weight: 400;
  line-height: 16.44px;
  text-align: center;
  min-width: 100px;
  color: white;
`;
